import { Avatar, Flex, Input, Radio, RadioGroup, Stack, useToast,  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button } from "@chakra-ui/react";
import { faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "ag-grid-community";
import axios from "axios";
import moment from "moment";
import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const AddSalaryStructure = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [EmpData, setEmpData] = useState([]);
  const navigate = useNavigate();
  const userId = useParams();
  //  console.log(userId);
  const guID = userId.guId;

  const [AdminCharges, setAdminCharges] = useState(0);
  const [IsHraApply, setIsHraApply] = useState([]);
  const [SalaryStartDate, setSalaryStartDate] = useState(new Date());
  const [SalaryEndDate, setSalaryEndDate] = useState(new Date());
  const [TotalEarnings, setTotalEarnings] = useState();
  const [TotalDeductions, setTotalDeductions] = useState();
  const [TotalEmployerContribution, setTotalEmployerContribution] = useState();
  const [TotalAdditions, setTotalAdditions] = useState();

  const [GrossSalary, setGrossSalary] = useState();
  const [NetSalary, setNetSalary] = useState();
  const [CTC, setCTC] = useState();

 const [EsiPFConfig, setEsiPFConfig] = useState([]);

 const [PfComponentList, setPfComponentList] = useState([]);
 const [PfEsiCalcValues, setPfEsiCalcValues] = useState([]);

 console.log("Pf ESI Calc Value",PfEsiCalcValues);

    const [EmpPF, setEmpPF] = useState(0);
    const [EmpESI, setEmpESI] = useState(0);
    const [EmpAdditionalPF, setEmpAdditionalPF] = useState(0);
  
    // Employer Contribution Variables
  
    const [ecpf, setECPF] = useState(0);
    const [ecesi, setECESI] = useState(0);

  
  const [EmployeeStructure, setEmployeeStructure] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [ESIPFSalaryValue, setESIPFSalaryValue] = useState([]);

  const [EmpCity, setEmpCity] = useState();
  const [HraConfig, setHraConfig] = useState([])


  useEffect(() => {
    GetEsiPFConfiguration();
    getEmp();
    GetEmpHraSettings();
  }, []);






  const getEmp = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
       const Emp = response.data;
       console.log("EMP response",response);
      setEmpData(Emp);
      // console.log("EmpData " , EmpData);
      getEmpStructure(Emp);

    } catch (error) {
      console.error(error);
    }
  };


  const getEmpStructure = async (EMPINFO) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDataByEmpGuid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );

      console.log("Employee structurre response",response);
      let EmpStructure = response.data


      // setSalaryStartDate(moment(EmpStructure.startDate).format('YYYY-MM-DD'));

      if(EmpStructure.grossSalary === 0){
        setSalaryStartDate(new Date(EMPINFO.dateOfJoin))
        setSalaryEndDate(new Date(moment(EMPINFO.dateOfJoin).add(12,'months').format('YYYY-MM-DD')))  
      }else{
        setSalaryStartDate(new Date(EmpStructure.startDate))
        setSalaryEndDate(new Date(EmpStructure.endDate))  

      }

      setEmployeeStructure(EmpStructure);
      setComponentList(EmpStructure.componantDataList);
      getEsiPFConfigValue(EmpStructure.employeeSalaryMasterId);
      GetPfComplianceSettings();
      GetPFESIValue();
      HRAConfigValue();

    } catch (error) {
      console.error(error);
    }
  };

  const GetEmpHraSettings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeeHRASettings?EmpGuId=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
      console.log("Emp HRA Applicable",response);
      setIsHraApply(response.data)
    } catch (error) {
      console.error(error);
    }
  }


  const HRAConfigValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetHRAConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("HRA Config Value",response);
      setHraConfig(response.data);
    } catch (error) {
      console.error(error);
    }
  }



  console.log("Pf Component List",PfComponentList);

  const GetPfComplianceSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetPFSettingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Pf Setting Component List",response);
      setPfComponentList(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const getEsiPFConfigValue = async (id) => {
    // console.log(id);
     try {
       const response = await axios.get(
         `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeeESIPFValueById?Id=${id}`
       );
       console.log("ESI PF Salary Value ",response);
       setESIPFSalaryValue(response.data);

     } catch (error) {
       console.error(error);
     }
   }


  const GetEsiPFConfiguration = async () => {
          try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeePFESICSettingEmpguid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("ESI PF Configuration",response);
        //  console.log("ESI PF Settings",response.data);
        setEsiPFConfig(response.data);
      } catch (error) {
        console.error(error);
      }
  }

  const GetPFESIValue = async () => {
    try {

      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/GetPFESICValue`
    )
      console.log("Get PF ESI Value",response);

      const res = response.data;
     setPfEsiCalcValues(res);

    } catch (error) {
      console.error(error);
    }
  }



  useEffect(() => {

    if (EsiPFConfig.employeePF === true) {
      setEmpPF(ESIPFSalaryValue.employeePFValue);
      setECPF(ESIPFSalaryValue.employerPFValue);
    }else{
      setEmpPF(0);
      setECPF(0);
    }
       
    if (EsiPFConfig.employeeAdditionalPF ===  true) {
      setEmpAdditionalPF(ESIPFSalaryValue.employeeAdditionalPFValue)
    }else{
      setEmpAdditionalPF(0);
    }


    if (EsiPFConfig.employyeESIC === true) {
      setECESI(ESIPFSalaryValue.employerESIValue);
      setEmpESI(ESIPFSalaryValue.employeeESIValue);
    }else{
      setECESI(0);
      setEmpESI(0);
    }

  }, [ESIPFSalaryValue])

  console.log("Admin Charges", AdminCharges);

  useEffect(()=>{

    let PfCompSum = componentList.filter((compData) => PfComponentList.find(PfCompData => compData.branchSalaryStructureMasterId === PfCompData.branchSalaryStructureMasterId)).reduce((prev, values) => {
      return Number(prev || 0) + Number(values.salaryValue ||0)
    }, 0);

    let CalcGross = componentList.filter((x) => {
      return x.componentType === 1;
    }).reduce((prev, values) => {
      return Number(prev || 0) + Number(values.salaryValue ||0)
    }, 0)

    // console.log("Filtered Components Sum",PfCompSum);

    if (EsiPFConfig.employeePF === true) {

      if(EsiPFConfig.employeeMaxCap){
        
          if(PfCompSum >= PfEsiCalcValues.pF_MaxCap){
            setEmpPF(1800);
            setECPF(1800);
            setAdminCharges(PfEsiCalcValues.pF_MaxCap*1/100);
          }else{
            setEmpPF(Math.round(PfCompSum*PfEsiCalcValues.epF_EmployeeContribution/100));
            setECPF(Math.round(PfCompSum*PfEsiCalcValues.epF_EmployerContribution/100));
            setAdminCharges(PfCompSum*1/100);

          }
      }else{
        setEmpPF(Math.round(PfCompSum*PfEsiCalcValues.epF_EmployeeContribution/100));
        setECPF(Math.round(PfCompSum*PfEsiCalcValues.epF_EmployerContribution/100));
        setAdminCharges(PfCompSum*1/100);
      }
    }else{
      setEmpPF(0);
      setECPF(0);
    }

    if (EsiPFConfig.employyeESIC === true ) {

      if(Number(CalcGross || 0) <= Number(PfEsiCalcValues.esI_MaxCap || 0)){
        setECESI(Math.round(CalcGross * PfEsiCalcValues.esiC_EmployerContribution/100) );
        setEmpESI(Math.round(CalcGross * PfEsiCalcValues.esiC_EmployeeContribution/100) );
      }else{
        setECESI(0);
        setEmpESI(0);
      }

    }else{
      setECESI(0);
      setEmpESI(0);
    }
    
  },[componentList,PfComponentList,PfEsiCalcValues,EsiPFConfig]);


  useEffect(() => {

    let BasicValue = componentList.filter(data => data.componentId === 1).map((BasicData)=>{ return BasicData.salaryValue})[0]
    //  console.log("Basic Value",BasicValue);
    
    if(IsHraApply.hraApplied){
      setComponentList(prev => {
        const newState =  prev.map((obj) => {
          if (obj.componentId === 2) {
            // let HraPercent = EmpCity === 'Metro' ? HraConfig.hraForMetroCity : HraConfig.hraForNonMetroCity;
            let HraPercent = IsHraApply.hraPercent;
            return {...obj, salaryValue: Math.round(BasicValue*HraPercent/100)};
          }
          return obj;
        });
        return newState
      })
    }

    setTotalEarnings(()=>{
      return componentList.filter((x) => {
        return x.componentType === 1;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0)
    })

    setTotalDeductions(()=>{
      return componentList.filter((x) => {
        return x.componentType === 2;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0) + Number(EmpPF || 0) + Number(EmpESI || 0) + Number(EmpAdditionalPF || 0)
    })


    setTotalEmployerContribution(()=>{
      return componentList.filter((x) => {
        return x.componentType === 3;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0) 
      }, 0)+ Number(ecpf || 0) + Number(ecesi || 0)
    })

    setTotalAdditions(()=>{
      return componentList.filter((x) => {
        return x.componentType === 4;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0)
    })

    setGrossSalary(()=>{
      return componentList.filter((x) => {
        return x.componentType === 1;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0)
    })

      setNetSalary(()=>{
      return componentList.filter((x) => {
        return x.componentType === 1;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0) + componentList.filter((x) => {
        return x.componentType === 4;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0) - componentList.filter((x) => {
        return x.componentType === 2;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0) 
      }, 0) - (Number(EmpPF || 0) + Number(EmpESI || 0) + Number(EmpAdditionalPF || 0))
    });

    // setCTC(()=>{
    //   return componentList.filter((x) => {
    //     return x.componentType === 1;
    //   }).reduce((prev, values) => {
    //     return Number(prev || 0) + Number(values.salaryValue ||0)
    //   }, 0) + 
    //   componentList.filter((x) => {
    //     return x.componentType === 3;
    //   }).reduce((prev, values) => {
    //     return Number(prev || 0) + Number(values.salaryValue ||0) 
    //   }, 0)+ Number(ecpf || 0) + Number(ecesi || 0) + 
    //    componentList.filter((x) => {
    //     return x.componentType === 4;
    //   }).reduce((prev, values) => {
    //     return Number(prev || 0) + Number(values.salaryValue ||0)
    //   }, 0) + componentList.filter((x) => {
    //     return x.componentType === 2;
    //   }).reduce((prev, values) => {
    //     return Number(prev || 0) + Number(values.salaryValue ||0)
    //   }, 0) + Number(EmpPF || 0) + Number(EmpESI || 0) + Number(EmpAdditionalPF || 0)+Number(AdminCharges || 0)
    // })
    

    setCTC(()=>{
      return componentList.filter((x) => {
        return x.componentType === 1;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0)
      }, 0) + 
      componentList.filter((x) => {
        return x.componentType === 3;
      }).reduce((prev, values) => {
        return Number(prev || 0) + Number(values.salaryValue ||0) 
      }, 0)+ Number(ecpf || 0) + Number(ecesi || 0)+Number(AdminCharges || 0)
    })


  }, [componentList,EmpPF,EmpESI,EmpAdditionalPF,ecpf,ecesi])

  // console.log("Employee Structure",EmployeeStructure);
  // console.log("Component List",componentList)


  const HandleMonthlyInput = (e,type,compId,label)=>{

    const { id, value } = e.target;

    // console.log("Input ID",Number(id));
    // console.log("Input Value",Number(value));
    //  console.log("Component Type",type);
    //  console.log("Component ID",Number(compId));
     


    setComponentList(prev => {
      const newState =  prev.map((obj) => {
        if (obj.branchSalaryStructureMasterId === Number(id)) {
          return {...obj, salaryValue: Number(value)};
        }
        return obj;
      });
      return newState
    }) 

  }


// console.log("Component List",componentList);
  const HandleSalarySave = async (e) => {

    e.preventDefault();

    let body = {
      employeeSalaryMasterId: Number(EmployeeStructure.employeeSalaryMasterId || 0),
      empGuid: guID,
      empCode: EmpData.empCode,
      adminCharges : AdminCharges,
      grossSalary:GrossSalary,
      startDate : moment(SalaryStartDate).format('YYYY-MM-DD'),
      endDate : moment(SalaryEndDate).format('YYYY-MM-DD'),
      netSalary:NetSalary,
      totalDeduction:TotalDeductions,
      ctc:CTC,
      employerTotalContribution:TotalEmployerContribution,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      componantDataList: componentList
    };

    console.log("Salary Save Body",body);


    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/SaveEmployeeSalaryData`,body
    ).then((response)=>{

      console.log("Save Salary Response", response);


      const res = response.data;

      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          // description: `${EmpData.fullName} Salary Created`,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      navigate("/salarydashboard");


    }).catch((error)=>{


      console.log("Save Salary Error",error);

      const err = error.message

      if (!toast.isActive(id)) {
        toast({
          id,
          title: err,
          description: `${EmpData.fullName} Salary not created`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }

    })



    if(EsiPFConfig.employeePF === true || EsiPFConfig.employyeESIC === true || EsiPFConfig.employeeAdditionalPF === true ||EsiPFConfig.employeePF === true || EsiPFConfig.employyeESIC === true){
  
      let body1 = {
        empGuid: guID,
        empCode: EmpData.empCode,
        employeeSalaryMasterId:EmployeeStructure.employeeSalaryMasterId,
        employeePFValue: EsiPFConfig.employeePF === true ? EmpPF : 0,
        employeeESIValue:EsiPFConfig.employyeESIC === true ? EmpESI : 0,
        employeeAdditionalPFValue:EsiPFConfig.employeeAdditionalPF === true ? EmpAdditionalPF : 0,
        employerPFValue: EsiPFConfig.employeePF === true ? ecpf : 0,
        employerESIValue: EsiPFConfig.employyeESIC === true ? ecesi : 0,
        companyId: EmpData.companyId,
        branchId: EmpData.branchId,
      }
      console.log("Esi Pf Values body",body1);

      await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/SaveEmployeeESIPFValue?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,body1)
      .then((response) => {
        console.log("Save Esi Pf Values Response",response);

        const res = response.data;
         // console.log("res",res);
        if (res === "Success") {

         // console.log("Data added Successfully", response);
          navigate("/salarydashboard");
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Employee Structure created.",
              description: `${EmpData.fullName} Salary Created`,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        }else if (res ===  "Updated") {

          // console.log("Data Updated", response);
          navigate("/salarydashboard");
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Salary Structure Updated",
              description: "Salary Structure Updated Successfully",
              position: "top",
              status: "warning",
              duration: 2000,
              isClosable: true,
            });
          }
          
        } else {
          //  console.log("Data Not added",response);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `${EmpData.fullName} Structure not created`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        }   
        // console.log("Data updated Successfully", response);
        // navigate('/settings/salarycomponents');
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
      });
      
      }

  }

  console.log("Is hra",IsHraApply);
  // console.log("Component Length",componentList.filter((data) => data.componentType === 2).length === 0 ? 1 : 0)

  return (
    <div>
      <div className="flex justify-between items-center border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Monthly Salary Structure</h1>
      </div>


     <form onSubmit={HandleSalarySave}>


      <div className="flex gap-4 ">

        <div className="basis-3/4 ">

          <div className="grid grid-cols-2 gap-4">

            <div className="flex flex-col gap-4">

            <Accordion defaultIndex={[0]} shadow='md' border="transparent" rounded="xl" className="h-fit" allowMultiple bg="white">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className=" text-left  flex-1 font-medium  text-gray-800">
                  Earnings
                </h6>

                <AccordionIcon />

              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>

              <div className="space-y-2">

              {componentList
          .filter((data) => data.componentType === 1)
          .map((data, i) => (
            <div
              className="flex space-y-1 flex-wrap md:justify-between"
              key={i}
            >
              <div className="space-x-2 my-auto">
                <label className="text-base font-medium">
                  {data.displayLabel}
                </label>
              </div>
              <div className="w-full md:w-1/2">
                <Input
                  isReadOnly={(data.componentId === 2) && IsHraApply?.hraApplied}
                  type="number"
                  borderColor="gray"
                  size="sm"
                  borderRadius="5"
                  value={data.salaryValue || ''}
                  id={data.branchSalaryStructureMasterId}
                  name={data.displayLabel}
                  placeholder={data.displayLabel}
                  onChange={(e)=>{HandleMonthlyInput(e,data.componentType,data.componentId,data.displayLabel)}}
                />
              </div>
            </div>
          ))}


              </div>


              <div className="border-t-2 mt-4 border-gray-400">
        <div className="flex justify-between pt-2">
          <label className="text-base font-semibold">
            Total Earnings :
          </label>
          <div className="w-2/5">
          <h6 className="text-base font-medium">{Number(TotalEarnings || 0)}</h6>
          </div>
        </div>
      </div>


            </AccordionPanel>
          </AccordionItem>

          
        </Accordion>

        <Accordion defaultIndex={[0]} shadow='md' border="transparent" rounded="xl" className="h-fit" allowMultiple bg="white">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className=" text-left  flex-1 font-medium  text-gray-800">
                  Employer Contribution
                </h6>
                <AccordionIcon />

              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <div className="space-y-2">

         {EsiPFConfig.employeePF === true ? (
            <div className="flex justify-between">
              <label className="text-base font-medium">EPF_ER</label>
              <div className="w-full md:w-1/2">
                <Input
                 isReadOnly
                  borderColor="gray"
                  value={ecpf || ''}
                  onChange={(e) => {
                    setECPF(parseInt(e.target.value));
                  }}
                  type="number"
                  size="sm"
                  borderRadius="5"
                  placeholder="Employer PF"
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {EsiPFConfig.employyeESIC === true ? (
            <div className="flex justify-between">
              <label className="text-base font-medium">ESI_ER</label>
              <div className="w-full md:w-1/2">
                <Input
                 isReadOnly
                  borderColor="gray"
                  value={ecesi || 0}
                  onChange={(e) => {
                    setECESI(parseInt(e.target.value));
                  }}
                  type="number"
                  size="sm"
                  borderRadius="5"
                  placeholder="Employer ESI"
                />
              </div>
            </div>
          ) : (
            ""
          )}

{componentList
.filter((data) => data.componentType === 3)
.map((data, i) => (
<div
className="flex space-y-1 flex-wrap md:justify-between"
key={i}
>
<div className="space-x-2 my-auto">
  <label className="text-base font-medium">
    {data.displayLabel}
  </label>
</div>
<div className="w-full md:w-1/2">
  <Input
    type="number"
    borderColor="gray"
    size="sm"
    borderRadius="5"
     value={data.salaryValue || ''}
    id={data.branchSalaryStructureMasterId}
    name={data.displayLabel}
    placeholder={data.displayLabel}
    onChange={(e)=>{HandleMonthlyInput(e,data.componentType,data.componentId,data.displayLabel)}}
  />
</div>
</div>
))}


</div>

<div className="border-t-2 mt-4 border-gray-400">
        <div className="flex justify-between pt-2">
          <label className="text-base font-semibold">
            Total Employer Contribution :
          </label>
          <div className="w-2/5">
          <h6 className="text-base font-medium">{Number(TotalEmployerContribution || 0)}</h6>
          </div>
        </div>
      </div>
            </AccordionPanel>
          </AccordionItem>

          
        </Accordion>

            </div>


            <div className="flex flex-col gap-4">

            <Accordion defaultIndex={[0]} shadow='md' border="transparent" rounded="xl" className="h-fit" allowMultiple bg="white">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className=" text-left  flex-1 font-medium  text-gray-800">
                  Deductions
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <div className="space-y-2">

              
          {EsiPFConfig.employeePF === true ? (
            <div className="flex justify-between">
              <label className="text-base font-medium">EPF_EE</label>
              <div className="w-full md:w-1/2">
                <Input
                 isReadOnly
                  borderColor="gray"
                  value={EmpPF || ''}
                  onChange={(e) => {
                    setEmpPF(parseInt(e.target.value));
                  }}
                  type="number"
                  size="sm"
                  borderRadius="5"
                  placeholder="Employee PF"
                />
              </div>
            </div>
          ) : (
            ""
          )}


          {EsiPFConfig.employyeESIC === true ? (
            <div className="flex justify-between">
              <label className="text-base font-medium">ESI_EE</label>
              <div className="w-full md:w-1/2">
                <Input
                 isReadOnly
                  borderColor="gray"
                  value={EmpESI || 0}
                  onChange={(e) => {
                    setEmpESI(parseInt(e.target.value));
                  }}
                  type="number"
                  size="sm"
                  borderRadius="5"
                  placeholder="Employee ESI"
                />
              </div>
            </div>
          ) : (
            ""
          )}


  {EsiPFConfig.employeeAdditionalPF === true ? (
            <div className="flex justify-between">
              <label className="text-base font-medium">Additional PF</label>
              <div className="w-full md:w-1/2">
                <Input
                  borderColor="gray"
                  value={EmpAdditionalPF || ''}
                  onChange={(e) => {
                    setEmpAdditionalPF(parseInt(e.target.value));
                  }}
                  type="number"
                  size="sm"
                  borderRadius="5"
                  placeholder="Additional PF"
                />
              </div>
            </div>
          ) : (
            ""
          )}

{componentList
.filter((data) => data.componentType === 2)
.map((data, i) => (
<div
className="flex space-y-1 flex-wrap md:justify-between"
key={i}
>
<div className="space-x-2 my-auto">
  <label className="text-base font-medium">
    {data.displayLabel}
  </label>
</div>
<div className="w-full md:w-1/2">
  <Input
    type="number"
    borderColor="gray"
    size="sm"
    borderRadius="5"
     value={data.salaryValue || ''}
    id={data.branchSalaryStructureMasterId}
    name={data.displayLabel}
    placeholder={data.displayLabel}
    onChange={(e)=>{HandleMonthlyInput(e,data.componentType,data.componentId,data.displayLabel)}}
  />
</div>
</div>
))}


</div>

<div className="border-t-2 mt-4 border-gray-400">
        <div className="flex justify-between pt-2">
          <label className="text-base font-semibold">
            Total Deductions :
          </label>
          <div className="w-2/5">
          <h6 className="text-base font-medium">{Number(TotalDeductions || 0)}</h6>
          </div>
        </div>
      </div>

            </AccordionPanel>
          </AccordionItem>

          
        </Accordion>

        <Accordion defaultIndex={[0]} shadow='md' border="transparent" rounded="xl" className="h-fit" allowMultiple bg="white">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className=" text-left  flex-1 font-medium  text-gray-800">
                  Additions
                </h6>
                <AccordionIcon />

              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <div className="space-y-2">

{componentList
.filter((data) => data.componentType === 4)
.map((data, i) => (
<div
className="flex space-y-1 flex-wrap md:justify-between"
key={i}
>
<div className="space-x-2 my-auto">
  <label className="text-base font-medium">
    {data.displayLabel}
  </label>
</div>
<div className="w-full md:w-1/2">
  <Input
    type="number"
    borderColor="gray"
    size="sm"
    borderRadius="5"
     value={data.salaryValue || ''}
    id={data.branchSalaryStructureMasterId}
    name={data.displayLabel}
    placeholder={data.displayLabel}
    onChange={(e)=>{HandleMonthlyInput(e,data.componentType,data.componentId,data.displayLabel)}}
  />
</div>
</div>
))}


</div>

<div className="border-t-2 mt-4 border-gray-400">
        <div className="flex justify-between pt-2">
          <label className="text-base font-semibold">
            Total Additions :
          </label>
          <div className="w-2/5">
          <h6 className="text-base font-medium">{Number(TotalAdditions || 0)}</h6>
          </div>
        </div>
      </div>

            </AccordionPanel>
          </AccordionItem>

          
        </Accordion>


            </div>






          </div>


        



        </div>

        <div className="basis-1/4">
 <div className='bg-white drop-shadow-md flex justify-between rounded-2xl p-4 gap-4 mb-4'>
 <div className="space-y-2">
        <label>Start Date</label>

<DatePicker
   required
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2 py-1.5 outline-none"
    selected={SalaryStartDate}
    onChange={(date) =>{
      setSalaryStartDate(date); 

    }}
    placeholderText="Salary Start Date"
    dateFormat={"dd-MM-yyyy"}
  />
        </div>

        <div className="space-y-2">
        <label>End Date</label>
        <DatePicker
   required
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2 py-1.5 outline-none"
    selected={SalaryEndDate}
    onChange={(date) =>{
      setSalaryEndDate(date); 

    }}
    placeholderText="Salary End Date"
    dateFormat={"dd-MM-yyyy"}
  />
        </div>


    </div>

    <div className="flex flex-col space-y-4 bg-gradient-to-br w-full from-slate-700 to-gray-400 rounded-3xl px-4">
    <div>
      <Flex py={8} className='space-y-4' w="full" direction='column' alignItems="center" justifyContent="center">
      <div className="bg-white flex justify-between p-4  rounded-3xl shadow-lg w-full max-w-md">
            <div className="">
              <h6 className="text-base font-semibold">
                Employee Name:- &nbsp;
                <span className="font-medium text-sm">
                  {EmpData.fullName}
                </span>
              </h6>
              <h6 className="text-base font-semibold">
                Employee Code:- &nbsp;
                <span className="font-medium text-sm">{EmpData.empCode}</span>
              </h6>
              <h6 className="text-base font-semibold">
                Designation:- &nbsp;
                <span className="font-medium text-sm">
                  {EmpData.designationName}
                </span>
              </h6>
              <h6 className="text-base font-semibold">
                Department:- &nbsp;
                <span className="font-medium text-sm">
                  {EmpData.departmentName}
                </span>
              </h6>
              <h6 className="text-base font-semibold">
                Date of Joining:- &nbsp;
                <span className="font-medium text-sm">
                  {moment(EmpData.dateOfJoin).format('DD/MM/YYYY')}
                </span>
              </h6>
              <h6 className="text-base font-semibold">
                Branch:- &nbsp;
                <span className="font-medium text-sm">
                  {EmpData.branchName}
                </span>
              </h6>
            </div>
          </div>


        <div className="bg-white flex justify-between p-4 w-full rounded-3xl shadow-lg max-w-sm">
      <div className="flex flex-col justify-between gap-4">
        <p className="text-sm font-medium text-gray-500">
          Gross Salary
        </p>
        <h6 className="text-xl font-semibold my-auto">{Number(GrossSalary || 0)}</h6>
      </div>
      <div className="align-middle my-auto">
        <div className="bg-orange-100 rounded-full">
          <Avatar
            bg="orange.100"
            size="md"
            icon={
              <FontAwesomeIcon
                className=" text-orange-600 text-xl"
                icon={faMoneyBill1Wave}
              />
            }
          />
        </div>
      </div>
    </div>

    <div className="bg-white flex justify-between p-4 w-full rounded-3xl shadow-lg max-w-sm">
      <div className="flex flex-col justify-between gap-4">
        <p className="text-sm font-medium text-gray-500">Net Salary</p>
        <h6 className="text-xl font-semibold my-auto">{Number(NetSalary || 0)}</h6>
      </div>
      <div className="align-middle my-auto">
        <div className="bg-orange-100 rounded-full">
          <Avatar
            bg="orange.100"
            size="md"
            icon={
              <FontAwesomeIcon
                className="text-orange-600 text-xl"
                icon={faMoneyBill1Wave}
              />
            }
          />
        </div>
      </div>
    </div>

    <div className="bg-white flex justify-between p-4 w-full rounded-3xl shadow-lg max-w-sm">
      <div className="flex flex-col justify-between gap-4">
        <p className="text-sm font-medium text-gray-500">CTC</p>
        <h6 className="text-xl font-semibold my-auto">{Number(CTC || 0)}</h6>
      </div>
      <div className="align-middle my-auto">
        <div className="bg-orange-100 rounded-full">
          <Avatar
            bg="orange.100"
            size="md"
            icon={
              <FontAwesomeIcon
                className=" text-orange-600 text-xl"
                icon={faMoneyBill1Wave}
              />
            }
          />
        </div>
      </div>
    </div>
      </Flex>
    </div>


  </div>
 </div>

      </div>

      <div className="text-right space-x-4 my-4">
        <Link to={'/salarydashboard'}>
         <Button colorScheme={'purple'} variant={'outline'}>Cancel</Button>
        </Link>
        <Button isDisabled={EmployeeStructure.isProRated} type="submit" colorScheme={'purple'}>Submit</Button>
      </div>

      </form>


    </div>
  );
};

export default AddSalaryStructure;
