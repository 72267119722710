import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faTriangleExclamation,
  faFileExport,
  faDownload,
  faUserTie,
  faBuildingUser,
} from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Edit, Eye, Plus, RefreshCw } from "react-feather";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Badge,
  Input,
  Button,
  Avatar,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import axios from "axios";

function SalaryDashboard() {
    const userData = JSON.parse(localStorage.getItem("user"));
    const [empStructure, setEmpStructure] = useState([]); // Modal Data Variable
    const gridRef = useRef();
    const [rowData, setRowData] = useState(); // Table Data Variable
    const [DashboardData, setDashboardData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const monthNames = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER",];
    const [EmpSalaryData, setEmpSalaryData] = useState([]);
    const [EsiPFConfig, setEsiPFConfig] = useState([]);
    const [ESIPFSalaryValue, setESIPFSalaryValue] = useState([]);

    let monthIndex = new Date().getMonth();
    // console.log(monthIndex);
    let monthName = monthNames[monthIndex];
    // console.log(monthName);
  
console.log("Emp Salary DAta",EmpSalaryData);
    const [SelectedEmpInfo, setSelectedEmpInfo] = useState([]);
  
    // Modal Variables and Functionality
    const { isOpen, onOpen, onClose } = useDisclosure();
  
      // API for Getting All Employee Details Table
      useEffect(() => {
  
        // setInterval(getEmp,10000);
        getEmp();
  
        getAnalyticsData();
      }, []);
  
      const getEmp = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
           console.log(response.data);
          const EmpList = response.data;
          UpdatePayrollStatus(response.data);
          setRowData(EmpList);
  
        } catch (error) {
          console.error(error);
        }
      };
  
  
      const UpdatePayrollStatus = async (Emplist) => {
        let body = Emplist.filter((data)=>data.isSalaryStructureCompleted === 0).map((object)=>{
          return {
            empGuid:object.guId,
            empCode:object.empCode,
            employeePayrollStatusInfoId:object.isSalaryStructureCompleted,
            companyId:object.companyId,
            branchId:object.branchId
          }
        });
  
       // console.log("Payroll Status Body",body);
  
       await axios
       .post(
         `${process.env.REACT_APP_ACQ_URL}/api/EmployeePayrollStatus/SaveEmpPayrollStatusInfo`,
         body,
         {
           headers:{
             "Content-Type": "application/json",
           },
         }
       )
       .then((response) => {
         const res = response.data;
          // console.log("res",res);
         if (res === "Success") {
           console.log("Payroll Status Updated Successfully", response);
  
         } else {
           console.log("Payroll Status Not Updated",response);
  
         }
       })
       .catch((error) => {
         console.log(error.response);
         console.log(error.response.data);
       });
  
      }
  
  
      const getAnalyticsData = async () => {
        try {
  
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDashboardData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
         // console.log(response.data);
          setDashboardData(response.data);
          
        } catch (error) {
          console.error(error);
        }
  
      }
  
  
  
  
    //API for Getting Particular Employee Salary Structure List and opening Modal by ID
    const HandleSalaryView = async (guId) => {
      //Passing ID by onClick
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDataByEmpGuid?EmpGuid=${guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Salary Data got Successfully", response.data);
        setEmpStructure(response.data);
        setEmpSalaryData(response.data.componantDataList)
        getEsiPFConfigValue(response.data.employeeSalaryMasterId);
      } catch (error) {
        console.error(error);
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeePFESICSettingEmpguid?EmpGuid=${guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("ESI PF Configuration",response);
        //  console.log("ESI PF Settings",response.data);
        setEsiPFConfig(response.data);
        onOpen(); // Calling Modal After Response

      } catch (error) {
        console.error(error);
      }
    };

    const getEsiPFConfigValue = async (id) => {
      // console.log(id);
       try {
         const response = await axios.get(
           `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeeESIPFValueById?Id=${id}`
         );
         console.log("ESI PF Salary Value ",response);
         setESIPFSalaryValue(response.data);
  
       } catch (error) {
         console.error(error);
       }
     }
  
  
    //Defining Columns Defiinitions Ex-fields data,headername,etc
    const [columnDefs, setColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode", },
      {
        headerName: "Emp Name",
        field: "fullName"
      },
      { headerName: "Designation", field: "designationName" },
    //   { headerName: "Department", field: "department" },
    //   { headerName: "City", field: "branchName" },
    //   {
    //     headerName: "Employee Status",
    //     field: "isActive",
    //     cellRenderer: (params) =>
    //       params.data.isActive === true ? (
    //         <span>Active</span>
    //       ) : (
    //         <span>InActive</span>
    //       ),
    //   },
      // { headerName: "Employee Type", field: "empTypeName" },
      {
        headerName: "Salary Structure Status",
        field: "isSalaryStructureCompleted",
        cellRenderer: (params) =>
          params.data.isSalaryStructureCompleted === 1 ? (
            <div className="text-center">
              <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Data Pending
              </span>
            </div>
          ) : params.data.isSalaryStructureCompleted === 2 ? (
            <div className="text-center">
              <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Completed
              </span>
            </div>
          
          ): (
            <div className="text-center">
              <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Pending
              </span>
            </div>
          ),
      },
      // {
      //   headerName: "Salary Data Status",
      //   field: "isSalaryDataStatus",
      //   cellRenderer: (params) =>
      //     params.data.isSalaryDataStatus === true ? (
      //       <div className="text-center">
      //         <span className="bg-green-600 rounded-md px-1 py-0.5 text-sm font-medium">
      //           Completed
      //         </span>
      //       </div>
      //     ) : (
      //       <div className="text-center">
      //         <span className="bg-red-500 rounded-md px-1 py-0.5 text-sm font-medium">
      //           Pending
      //         </span>
      //       </div>
      //     ),
      // },
      {
        headerName: "Salary Structure",
        filter: false,
        cellRenderer: (params) =>
          params.data.isSalaryStructureCompleted === 1 || params.data.isSalaryStructureCompleted === 2 ? (
            <div className="flex gap-4">
  
              <Link to={`/addcomponenets/${params.data.guId}`}>
              <Button size={'xs'}  colorScheme={'blue'}><Edit size={'12px'} className='mr-1' />Edit</Button>
              </Link>
            </div>
          ) : (
            <div className="space-x-4">
              <Link to={`/addcomponenets/${params.data.guId}`}>
              <Button size={'xs'}  colorScheme={'blue'}><Plus size={'12px'} className='mr-1' />Add Components</Button>
              </Link>
  
            </div>
          ),
      },
      {
        headerName: "Salary Data",
        filter: false,
        cellRenderer: (params) =>
          params.data.isSalaryStructureCompleted  === 2 ? (
            <div className="flex gap-6 items-center">
              {/* <Link to='/viewsalary'> */}
              <Button size={'xs'} onClick={()=>{
                setSelectedEmpInfo(params.data);
                HandleSalaryView(params.data.guId);
                }}  colorScheme={'blue'}><Eye size={'12px'} className='mr-1' />View</Button>

               {/* </Link> */}
  
              <Link to={`/salary/${params.data.guId}`}>
              <Button size={'xs'}  colorScheme={'blue'}><Edit size={'12px'} className='mr-1' />Modify</Button>
              </Link>
  
  
              
            </div>
          ) : ( params.data.isSalaryStructureCompleted === 1?
            <div className="space-x-4">
              <Link to={`/salary/${params.data.guId}`}>
              <Button size={'xs'}  colorScheme={'blue'}><Plus size={'12px'} className='mr-1' />Add Salary</Button>

              </Link>
            </div>:("")
          ),
      },
    ]);
  
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      resizable:true
    }));
  
  
    const callRefreshAfterMillis = useCallback(() => {
      gridRef.current.api.refreshCells();
      setInterval(callRefreshAfterMillis, 1000);
    }, []);
  
    // Table Gloabal Search Input
    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    }, []);
  
    //Table Export Button
    const onBtnExport = useCallback(() => {
      gridRef.current.api.exportDataAsCsv();
    }, []);
  
  
  
    console.log("Selected EMP Info",SelectedEmpInfo);
  
  
  
    return (
      <div>

<div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Salary Dashboard</h1>
      </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg ">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">Total Employees</p>
  
              <h6 className="text-3xl font-bold">{DashboardData.totalEmployee}</h6>
  
              <p className="text-xs text-gray-500">Head Count</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg ">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">
                Total Departments
              </p>
              <h6 className="text-3xl font-bold">{DashboardData.department}</h6>
              <p className="text-xs text-gray-50">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-orange-100 rounded-full">
                <Avatar
                  bg="orange.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-orange-600 text-xl"
                      icon={faBuildingUser}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg ">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">New Joinee</p>
              <h6 className="text-3xl font-bold">{DashboardData.newJoinee}</h6>
              <p className="text-xs text-gray-500">{monthName}</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-purple-100 rounded-full ">
                <Avatar
                  bg="purple.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-purple-600 text-xl"
                      icon={faUserTie}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg ">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">
                Pending Salary Structure
              </p>
              <h6 className="text-3xl font-bold">{DashboardData.pendingSalaryStructure}</h6>
              <p className="text-xs text-gray-500">No. of Employees</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full">
                <Avatar
                  bg="red.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-red-600 text-xl"
                      icon={faTriangleExclamation}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
  

        <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
            Employee Details
          </h1>
          <div className=" flex gap-4 items-center">
          <div >
            <Input
              placeholder="Search Globally"
              id="filter-text-box"
              backgroundColor="white"
              borderRadius="8"
              borderColor="gray"
              onInput={onFilterTextBoxChanged}
              size="sm"
            />
          </div>
          <Button
            leftIcon={<FontAwesomeIcon icon={faDownload} />}
            onClick={onBtnExport}
            colorScheme="purple"
            size={'sm'}
          >
            Export CSV
          </Button>
        </div>
        </div>


        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            callRefreshAfterMillis={callRefreshAfterMillis}
            suppressExcelExport={true}
          />
        </div>
  
  
        <Modal isOpen={isOpen} size={"3xl"} onClose={onClose}>
          <ModalOverlay />
          <ModalContent rounded={'3xl'}>
           <ModalHeader backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'}  >Salary Structure</ModalHeader>
           <ModalCloseButton mt={2} />

            <ModalBody>

              <div className="flex gap-6 mb-2">
                <div className="space-y-1">
                 <p className="text-sm text-gray-500">Employee Name</p>
                 <h6 className="font-medium">{SelectedEmpInfo.fullName}</h6>
                </div>
                <div className="space-y-1">
                 <p className="text-sm text-gray-500">Employee Code</p>
                 <h6 className="font-medium">{SelectedEmpInfo.empCode}</h6>
                </div>
                <div className="space-y-1">
                 <p className="text-sm text-gray-500">Designation</p>
                 <h6 className="font-medium">{SelectedEmpInfo.designationName}</h6>
                </div>
                <div className="space-y-1">
                 <p className="text-sm text-gray-500">Department</p>
                 <h6 className="font-medium">{SelectedEmpInfo.departmentName}</h6>
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-7 my-4">


              <div>
                <h2 className=" mb-2 font-semibold">Earnings</h2>
                <hr />
                {EmpSalaryData.filter((data) => data.componentType === 1).map((data,i)=>{
                     return <div key={i} className="flex justify-between mt-2">
                        <label className="font-medium">{data.displayLabel}</label>
                        <p>{data.salaryValue}</p>
                      </div>
                })}
              </div>
  
              <div>
               <h2 className="text-lg mb-2 font-semibold">Deductions</h2>
                <hr />
                {EmpSalaryData.filter((data) => data.componentType === 2).map((data,i)=>{
                     return <div key={i} className="flex justify-between mt-2">
                        <label className="font-medium">{data.displayLabel}</label>
                        <p>{data.salaryValue}</p>
                      </div>
                })}

                {EsiPFConfig.employeePF && (
                  <div className="flex justify-between mt-2">
                  <label className="font-medium">EPF_EE</label>
                  <p>{ESIPFSalaryValue.employeePFValue || 0}</p>
                </div>
                )}

                {EsiPFConfig.employyeESIC && (
                  <div className="flex justify-between mt-2">
                  <label className="font-medium">ESI_EE</label>
                  <p>{ESIPFSalaryValue.employeeESIValue || 0}</p>
                </div>
                )}

                {EsiPFConfig.employeeAdditionalPF && (
                  <div className="flex justify-between mt-2">
                  <label className="font-medium">Additional PF</label>
                  <p>{ESIPFSalaryValue.employeeAdditionalPFValue || 0}</p>
                </div>
                )}
  
              </div>
  
              <div>
              <h2 className="text-lg mb-2 font-semibold">Employer Contribution</h2>
               <hr />
               {EmpSalaryData.filter((data) => data.componentType === 3).map((data,i)=>{
                     return <div key={i} className="flex justify-between mt-2">
                        <label className="font-medium">{data.displayLabel}</label>
                        <p>{data.salaryValue}</p>
                      </div>
                })}

                {EsiPFConfig.employeePF && (
                  <div className="flex justify-between mt-2">
                  <label className="font-medium">EPF_ER</label>
                  <p>{ESIPFSalaryValue.employerPFValue || 0}</p>
                </div>
                )}

                {EsiPFConfig.employyeESIC && (
                  <div className="flex justify-between mt-2">
                  <label className="font-medium">ESI_ER</label>
                  <p>{ESIPFSalaryValue.employerESIValue || 0}</p>
                </div>
                )}
  
              </div>
  

              <div>
              <h2 className="text-lg mb-2 font-semibold">Additions</h2>
               <hr />
               {EmpSalaryData.filter((data) => data.componentType === 4).map((data,i)=>{
                     return <div key={i} className="flex justify-between mt-2">
                        <label className="font-medium">{data.displayLabel}</label>
                        <p>{data.salaryValue}</p>
                      </div>
                })}
  
              </div>

  
              </div>
  
              <hr />
  
              <div className="flex gap-8 mt-6">

                <div className="space-y-2 bg-blue-200 p-2 rounded-xl drop-shadow">
                 <label className="font-medium">Gross Salary</label>
                  <p>{empStructure.grossSalary}</p>
                </div>
  
                <div className="space-y-2 bg-blue-200 p-2 rounded-xl drop-shadow">
                 <label className="font-medium">Net Salary</label>
                  <p>{empStructure.netSalary}</p>
                </div>

                <div className="space-y-2 bg-blue-200 p-2 rounded-xl drop-shadow">
                 <label className="font-medium">CTC</label>
                  <p>{empStructure.ctc}</p>
                </div>
  
  
  
              </div>
            </ModalBody>
  <hr />
            <ModalFooter>
              <Button variant={'solid'} onClick={onClose} colorScheme="purple">Close</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }

export default SalaryDashboard