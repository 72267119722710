import React, { useState, useMemo } from 'react';
import { Box, Input, Button, VStack, HStack, Heading, useToast } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';

const PayrollAttendance = () => {
  // Retrieve company ID and branch ID from local storage
  const userData = JSON.parse(localStorage.getItem("user"));
  const companyId = userData.companyId;
  const branchId = userData.isActiveBranch;

  // State for input fields
  const [payrollMonth, setPayrollMonth] = useState(9);
  const [payrollYear, setPayrollYear] = useState(2024);

  // State for grid data
  const [attendanceData, setAttendanceData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false); // State to track if processing is in progress
  const toast = useToast();

  // Column definitions for Ag-Grid
  const columnDefs = useMemo(() => [
    { headerName: 'Emp Code', field: 'empCode', sortable: true, filter: true },
    { headerName: 'Emp Name', field: 'empName', sortable: true, filter: true },
    { headerName: 'Payable Days', field: 'totalPresent', sortable: true, filter: true, editable: true }, // Allow editing for Payable Days
    {
      headerName: 'Action',
      field: 'action',
      cellRendererFramework: (params) => (
        <Button
          size="sm"
          colorScheme="blue"
          onClick={() => handleUpdateAttendance(params.data)}
        >
          Update
        </Button>
      )
    }
  ], []);

  // Memoized column definition for Ag-Grid
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true
  }), []);

  // Function to fetch attendance data
  const fetchAttendanceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetClientPayrollAttendance`,
        {
          params: {
            PayrollMonth: payrollMonth,
            PayrollYear: payrollYear,
            CompanyId: companyId,
            BranchId: branchId
          }
        }
      );
      console.log('Attendance Data:', response.data); // Check the response data
      setAttendanceData(response.data);
      toast({
        title: 'Attendance data fetched successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error fetching attendance data.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  // Function to update attendance data
  const handleUpdateAttendance = async (data) => {
    setIsProcessing(true); // Set processing to true
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/UpdatePayrollAttendance`,
        {
          EmpGuId: data.empGuId,
          EmpCode: data.empCode,
          EmpName: data.empName,
          TotalPresent: data.totalPresent,
          TotalLeaveBalance: data.totalLeaveBalance,
          PayrollMonth: payrollMonth,
          PayrollYear: payrollYear,
          CompanyId: companyId,
          BranchId: branchId
        }
      );
      toast({
        title: response.data.errorMessage || 'Attendance updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      fetchAttendanceData(); // Refresh data after updating
    } catch (error) {
      toast({
        title: 'Error updating attendance data.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsProcessing(false); // Set processing to false
    }
  };

  // Function to process attendance data
  const processAttendanceData = async () => {
    setIsProcessing(true); // Set processing to true
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/ProcessPayrollAttendance`,
        {
          PayrollMonth: payrollMonth,
          PayrollYear: payrollYear,
          CompanyId: companyId,
          BranchId: branchId
        }
      );
      toast({
        title: 'Attendance processed successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      fetchAttendanceData(); // Refresh data after processing
    } catch (error) {
      toast({
        title: 'Error processing attendance data.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsProcessing(false); // Set processing to false
    }
  };

  return (
    <Box p={5}>
      <Heading mb={6}>Payroll Attendance Management</Heading>

      {/* Input Fields */}
      <VStack spacing={4} align="flex-start" mb={6}>
        <HStack spacing={4}>
          <Box>
            <label>Payroll Month:</label>
            <Input 
              type="number" 
              value={payrollMonth} 
              onChange={(e) => setPayrollMonth(Number(e.target.value))} 
              placeholder="Enter Payroll Month"
              size="sm"
            />
          </Box>
          <Box>
            <label>Payroll Year:</label>
            <Input 
              type="number" 
              value={payrollYear} 
              onChange={(e) => setPayrollYear(Number(e.target.value))} 
              placeholder="Enter Payroll Year"
              size="sm"
            />
          </Box>
        </HStack>
        <HStack spacing={4}>
          <Button 
            onClick={fetchAttendanceData} 
            colorScheme="teal" 
            size="sm"
            isLoading={isProcessing} // Add loading indicator while processing
          >
            Fetch Attendance
          </Button>
          <Button 
            onClick={processAttendanceData} 
            colorScheme="blue" 
            size="sm"
            isDisabled={isProcessing} // Disable the button while processing
            isLoading={isProcessing} // Add loading indicator while processing
          >
            Process Attendance
          </Button>
        </HStack>
      </VStack>
      
      {/* Ag-Grid Table with Pagination */}
      <Box className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          rowData={attendanceData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef} // Apply the memoized column definition
          pagination={true} // Enable pagination
          paginationPageSize={20} // Display 20 rows per page
          domLayout='autoHeight' // Automatically adjust the grid height
        />
      </Box>
    </Box>
  );
};

export default PayrollAttendance;
