import React,{useRef,useMemo,useState, useCallback, useEffect} from 'react'
import axios from "axios";
import { Avatar, Button, useDisclosure } from "@chakra-ui/react";
import {Modal,useToast,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalHeader,ModalOverlay } from '@chakra-ui/react'
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faTriangleExclamation,
  faUserTie,
  faBuildingUser,faDownload
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";

import _ from 'lodash';
import moment from 'moment';

function ViewAllSalary() {
    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = 'toast';
    const [AllEmpData, setAllEmpData] = useState([]);
    const [MasterData, setMasterData] = useState([]);
    const [componentList, setComponentList] = useState([]);
    const [DashboardData, setDashboardData] = useState([]);
    const [SampleExcelData, setSampleExcelData] = useState([]);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  
    const {
      isOpen: isImportSalaryDataOpen,
      onOpen: onImportSalaryDataOpen,
      onClose: onImportSalaryDataClose,
    } = useDisclosure();
  
    const monthNames = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    let monthIndex = new Date().getMonth();
    let monthName = monthNames[monthIndex];
    const [ImportData, setImportData] = useState([]);
  
    useEffect(() => {
      getAllEmpData();
      getMasterStructure();
      getAnalyticsData();
      getEmpList();
    }, []);
  
    useEffect(() => {
      Promise.all(
        AllEmpData.filter((data) => data.isSalaryStructureCompleted === 2).map(
          async (data) => {
            const response = await fetch(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryReportByEmpGuid?EmpGuid=${data.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
            const Empdata = await response.json();
            //  console.log(Empdata);
            return setComponentList((prev) => {

              let componentData = Empdata.empSalaryData;
             let FinalRowdata = componentData.map((data)=>{
                let rowData = [];
                rowData.push({[data.branchSalaryStructureMasterId+'salaryValue']: data.salaryValue});
                return  Object.assign(Empdata,...rowData)
              })
              let UniqData = _.uniqBy(FinalRowdata,'empGuid')
              // console.log("Uniq Row Data",UniqData);
              return [...prev,UniqData];
            });
          }
          
        )
      );



    }, [AllEmpData]);


    useEffect(() => {
       console.log("Component List",_.flatten(componentList));


      setRowData(_.flatten(componentList));
      

    }, [componentList])
    

  

    const getAnalyticsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDashboardData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        // console.log(response.data);
        setDashboardData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    const getAllEmpData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log( " All Employees",response.data);
        setAllEmpData(response.data);

        // console.log("Emp Row Data",CompletedEmp)
      
        //setRowData();
      } catch (error) {
        console.error(error);
      }
    };
  
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const defaultColDef = useMemo(() => ({
      //  flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      resizable : true
    }));
  
  
    const getEmpList = async () => {
      try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
        //  console.log("Employee List ",response.data);
  
        let res = response.data;
        const PendingStructure = res.filter((data)=>data.isSalaryStructureCompleted === 0);
        // console.log("Pending Structures" , PendingStructure);
        setSampleExcelData(PendingStructure);
      } catch (error) {
          console.error(error);
      }
    }
  
  
    const getMasterStructure = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        .then((response) => {
          //  console.log("Branch Header", response.data);
           let resdata = response.data

           let ActiveComp = resdata.filter(data => data.isActive  === true)
          setMasterData(resdata);
          HandleBranchExcel(ActiveComp);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const [columnDefs, setColumnDefs] = useState();

  
    const [Heads, setHeads] = useState([
      { label: "GuId", key: `guId`},
      { label: "FirstName", key: `firstName`},
      { label: "LastName", key: `lastName`},
      { label: "EmpCode", key: `empCode`},
  
    ])
  

  


  
    const HandleBranchExcel = async(branchComponents) => {
  
      // console.log(branchComponents);
  
      branchComponents.map(obj=>{
        setHeads(prev => [...prev, { label: obj.displayLabel, key: obj.displayLabel }])
      })


      let StartingHeaders = [
        { headerName: "Emp Code", field: "empCode", },
        {
          headerName: "Emp Name",
          field: "empName"
        },
        {
          headerName: "Designation",
          field: "designationName"
        },
        {
          headerName: "Department",
          field: "departmentName"
        },
        {
          headerName: "Date of Joining",
          field: "dateOfJoining",
          valueGetter: (params) => moment(params.data.dateOfJoining).format('DD/MM/YYYY')
        },
      ]

      let MiddleHeaders = branchComponents.map(obj=>{
        return { headerName: obj.displayLabel, 
          field: obj.branchSalaryStructureMasterId + 'salaryValue',
        }
      })

      let EndHeads = userData.countryName === "India" ? [
        { headerName: "Emp PF", field: 'pF_Employee', valueGetter:(params) => Number(params.data.pF_Employee)},
        { headerName: "Emp_ER PF", field: 'pF_Employer', valueGetter:(params) => Number(params.data.pF_Employer)},
        { headerName: "Additional PF", field: 'pF_Additional', valueGetter:(params) => Number(params.data.pF_Additional)},
        { headerName: "Emp ESI", field: 'esI_Employee', valueGetter:(params) => Number(params.data.esI_Employee)},
        { headerName: "Emp_ER ESI", field: 'esI_Employer', valueGetter:(params) => Number(params.data.esI_Employer)},
        { headerName: "Gross", field: `gross`,valueGetter:(params) => Number(params.data.gross)},
        { headerName: "Net Salary", field: `netPay`,valueGetter:(params) => Number(params.data.netPay)},
        { headerName: "CTC", field: `ctc`,valueGetter:(params) => Number(params.data.ctc)}
      ] : [
        { headerName: "Gross", field: `gross`,valueGetter:(params) => Number(params.data.gross)},
        { headerName: "Net Salary", field: `netPay`,valueGetter:(params) => Number(params.data.netPay)},
        { headerName: "CTC", field: `ctc`,valueGetter:(params) => Number(params.data.ctc)}
      ]

      console.log("View Salary Headers",_.union(StartingHeaders,MiddleHeaders,EndHeads));

      setColumnDefs(_.union(StartingHeaders,MiddleHeaders,EndHeads))

      userData.countryName === "India" ?
      setHeads(prev => [...prev,
        { label: "Gross", key: `gross`},
        { label: "NetSalary", key: `netSalary`},
        { label: "EmployeePFValue", key: `EmployeePFValue`},
        { label: "EmployerPFValue", key: `EmployerPFValue`},
        { label: "EmployeeAdditionalPFValue", key: `EmployeeAdditionalPFValue`},
        { label: "EmployeeESIValue", key: `EmployeeESIValue`},
        { label: "EmployerESIValue", key: `EmployerESIValue`}
      ]) : setHeads(prev => [...prev,
        { label: "Gross", key: `gross`},
        { label: "NetSalary", key: `netSalary`},
        { label: "CTC", key: `ctc`}
      ])    
    }
  

    const readExcel = (file)=>{
  
      const promise = new Promise((resolve,reject) => {
        
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
   
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
   
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
   
         const ws = wb.Sheets[wsname];
   
         const data = XLSX.utils.sheet_to_json(ws);
   
         resolve(data)
       } 
   
       fileReader.onerror = (error) => {
         reject(error)
       }
   
      });
   
      promise.then((data)=>{
        console.log(data);
  
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch
          }
         })
         console.log("Final Data",FinalData);
       setImportData(FinalData);
       handleImportSalaryData(FinalData)
      });
   
   }
  
  
   const handleImportSalaryData = async (data) =>{
  
      console.log("Import Data",data);

      if(data.some(item => item.Gross === 0) || data.some(item => item.NetSalary === 0) || data.some(item => item.Basic === 0) || data.some(item => item.HRA === 0)){
        alert('Basic, HRA, Gross Salary, Net Salary Amount Cannot Be 0');
      }else{
        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/ImportEmployeeSalaryStructure`,data
        )
        .then((response) => {
          console.log("Import Response",response);
          const res = response.data;
          console.log("res",res);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
      
      
        })
        .catch((error) => {
          console.log("Import Error",error);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Salary Data not Imported.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
      }


    }



  
  
    return (
      <div>

<div className="justify-between flex  border-b border-gray-400 mb-3 pb-2 items-center text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
           View Employee Salary Details
          </h1>
  
          <div>
  
          <Button onClick={onImportSalaryDataOpen} size={'sm'} colorScheme='purple'>
              Import Employee Salary Data
            </Button>
  
          </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">Total Employees</p>
  
              <h6 className="text-2xl font-bold">
                {DashboardData.totalEmployee}
              </h6>
  
              <p className="text-xs text-gray-500">Head Count</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">
                Total Departments
              </p>
              <h6 className="text-2xl font-bold">{DashboardData.department}</h6>
              <p className="text-xs text-gray-500">-</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-orange-100 rounded-full">
                <Avatar
                  bg="orange.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-orange-600 text-xl"
                      icon={faBuildingUser}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">New Joinee</p>
              <h6 className="text-2xl font-bold">{DashboardData.newJoinee}</h6>
              <p className="text-xs text-gray-500">{monthName}</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-purple-100 rounded-full ">
                <Avatar
                  bg="purple.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-purple-600 text-xl"
                      icon={faUserTie}
                    />
                  }
                />
              </div>
            </div>
          </div>
  
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg">
            <div className="flex flex-col justify-between gap-4">
              <p className="text-xs font-medium text-gray-500">
                Pending Salary Structure
              </p>
              <h6 className="text-2xl font-bold">
                {DashboardData.pendingSalaryStructure}
              </h6>
              <p className="text-xs text-gray-500">No. of Employees</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full">
                <Avatar
                  bg="red.100"
                  size="md"
                  icon={
                    <FontAwesomeIcon
                      className=" text-red-600 text-xl"
                      icon={faTriangleExclamation}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>


        <div className='text-right mb-4'>
          <Button onClick={()=>{gridRef.current.api.exportDataAsCsv()}} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'} >Export</Button>
        </div>
  

        <div className="ag-theme-alpine mb-4">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
          />
        </div>


  
  
  
        <Modal size='full' isOpen={isImportSalaryDataOpen} onClose={onImportSalaryDataClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Import Employee Salary</ModalHeader>
            <ModalCloseButton onClick={()=>{setImportData([])}} />
            <hr />
              <ModalBody>
  
                <div className='mt-4'>
                <CSVLink data={SampleExcelData} headers={Heads} filename={"Employee Salary Structue.csv"}>
              <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel</Button>
            </CSVLink>
                </div>
              <div className="flex justify-center items-center w-full my-10">
            <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
                </div>
                <input onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }} id="dropzone-file" type="file" className="hidden" />
            </label>
        </div> 
  
        <div>
          {JSON.stringify(ImportData)}
        </div>
  
  
        {/* <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={ImportData} // Row Data for Rows
            columnDefs={ImportDataColDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
          />
        </div> */}
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
  
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    mr={3}
                    onClick={()=>{setImportData([]);
                      onImportSalaryDataClose()}}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
          </ModalContent>
        </Modal>
  
      </div>
    );
  }

export default ViewAllSalary